import React from 'react';

import ServiceForm from '@/components/Forms/ServiceForm';
import {
  getServicePageOptions,
  handleSubmitServiceForm,
} from '@/modules/Service/common.helpers';
import { useTypedSelector } from '@/store/store';
import {
  getServiceBrands,
  getServiceSingleLanding,
} from '@/store/reducers/service';
import { OptionType } from '@/typings/common';
import { initialServiceFormValues } from '@/components/Forms/ServiceForm/helpers';
import { useServiceContext } from '@/modules/Service/common.hooks';

function Form() {
  const serviceSingleLanding = useTypedSelector(getServiceSingleLanding);
  const serviceBrands = useTypedSelector(getServiceBrands);
  const { selectedDealer } = useServiceContext();

  if (!serviceSingleLanding) {
    return null;
  }

  const { brands, dealers } = getServicePageOptions(
    serviceSingleLanding.serviceCenters,
    serviceBrands
  );

  const foundDealer = (selectedDealer &&
    dealers.find((dealer) => dealer.dealer.label.includes(selectedDealer.name))
      ?.dealer) ?? { label: '', value: '' };

  const { name, formTitle, formEvent } = serviceSingleLanding;

  const service: OptionType = { label: name, value: name };

  return (
    <ServiceForm
      title={formTitle ?? undefined}
      buttonTitle="Записаться"
      options={{ brands, dealers, services: [service] }}
      initialFields={{
        ...initialServiceFormValues,
        service,
      }}
      setFields={{ dealer: foundDealer }}
      disabledFields={{ service: true }}
      onSubmit={(values) => handleSubmitServiceForm(values, formEvent ?? '')}
      isMobile
    />
  );
}

export default Form;
