import * as Yup from 'yup';
import { omit } from 'lodash';

import { OptionType } from '@/typings/common';

import {
  ConsultationFormSecondaryFieldsConfig,
  ConsultationFormSecondaryFieldsSchema,
} from './types';

export const getDealersOptions = (
  dealers: { dealer: OptionType; brands: OptionType[] }[]
): OptionType[] => {
  return dealers.map((dealerItem) => ({
    label: dealerItem.dealer.label,
    value: dealerItem.dealer.value,
  }));
};

export const getDealersOptionsByBrand = (
  dealers: { dealer: OptionType; brands: OptionType[] }[],
  searchBrand: string
): OptionType[] => {
  return dealers
    .filter((dealer) =>
      dealer.brands.some((brand) => brand.value === searchBrand)
    )
    .map((dealerItem) => ({
      label: dealerItem.dealer.label,
      value: dealerItem.dealer.value,
    }));
};

export const getResultConsultationFormSecondaryValues = (
  values: ConsultationFormSecondaryFieldsSchema,
  fieldsConfig?: ConsultationFormSecondaryFieldsConfig
) => {
  const paths: Array<keyof ConsultationFormSecondaryFieldsSchema> = [];

  if (!fieldsConfig?.withBrands) {
    paths.push('brand');
  }

  if (!fieldsConfig?.withDealers) {
    paths.push('dealer');
  }

  if (!fieldsConfig?.withMessage) {
    paths.push('message');
  }

  return omit(values, paths) as ConsultationFormSecondaryFieldsSchema;
};

export const initialConsultationFormSecondaryValues: ConsultationFormSecondaryFieldsSchema =
  {
    name: '',
    phone: '',
    brand: { label: '', value: '' },
    dealer: { label: '', value: '' },
    message: '',
  };

export const validationConsultationFormSecondarySchema: Yup.SchemaOf<ConsultationFormSecondaryFieldsSchema> =
  Yup.object().shape({
    name: Yup.string().required('Укажите имя'),
    phone: Yup.string().required('Укажите номер телефона'),
    brand: Yup.object({
      label: Yup.string().required('Выберите марку автомобиля'),
      value: Yup.string().required('Выберите марку автомобиля'),
    }),
    dealer: Yup.object({
      label: Yup.string().required('Выберите автоцентр'),
      value: Yup.string().required('Выберите автоцентр'),
    }),
    message: Yup.string(),
  });
