import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { FormikErrors, useFormik } from 'formik';

import { convertRequestErrorToMap, useUpdateEffect } from '@tager/web-core';
import { convertThumbnailToPictureImage } from '@tager/web-modules';

import Picture from '@/components/Picture';
import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';
import StockFilterInput from '@/components/StockFilterInput';
import { CheckboxAgreement } from '@/components/Checkbox';
import Loader from '@/components/Loader';
import Button from '@/components/Button';
import SuccessForm from '@/components/Forms/SuccessForm';
import StockFilterTextArea from '@/components/StockFilterTextArea';
import StockFilterSelect from '@/components/StockFilterSelect';
import { OptionType } from '@/typings/common';

import {
  ConsultationFormSecondaryProps,
  ConsultationFormSecondaryFieldsSchema,
} from './types';
import {
  getResultConsultationFormSecondaryValues,
  getDealersOptions,
  getDealersOptionsByBrand,
  initialConsultationFormSecondaryValues,
  validationConsultationFormSecondarySchema,
} from './helpers';

function ConsultationFormSecondary({
  image,
  imageMobile,
  title,
  buttonText,
  brands,
  dealers,
  fieldsConfig,
  onSubmit,
}: ConsultationFormSecondaryProps) {
  const [isFormSend, setFormSend] = useState(false);
  const [userAgreement, setUserAgreement] = useState(false);
  const [brand, setBrand] = useState<OptionType>({ label: '', value: '' });
  const [dealer, setDealer] = useState<OptionType>({ label: '', value: '' });

  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    setFieldValue,
    setErrors,
    isSubmitting,
  } = useFormik<ConsultationFormSecondaryFieldsSchema>({
    initialValues: initialConsultationFormSecondaryValues,
    validationSchema: validationConsultationFormSecondarySchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);

      try {
        await onSubmit?.(
          getResultConsultationFormSecondaryValues(values, fieldsConfig)
        ).then(() => {
          setFormSend(true);
        });
      } catch (error: any) {
        const errorMap = convertRequestErrorToMap(
          error
        ) as FormikErrors<ConsultationFormSecondaryFieldsSchema>;
        setErrors(errorMap);
        console.log(error);
      }

      setSubmitting(false);
    },
  });

  useEffect(() => {
    if (!fieldsConfig?.withBrands) {
      setFieldValue('brand', { label: 'default', value: 'default' });
    }

    if (!fieldsConfig?.withDealers) {
      setFieldValue('dealer', { label: 'default', value: 'default' });
    }
  }, []);

  useUpdateEffect(() => {
    setFieldValue('brand', brand);

    if (dealer.value) {
      const foundDealer = dealers
        ?.find((currentDealer) => currentDealer.dealer.value === dealer.value)
        ?.brands.find((currentBrand) => currentBrand.value === brand.value);

      if (!foundDealer) {
        setDealer({ label: '', value: '' });
      }
    }
  }, [brand]);

  useUpdateEffect(() => {
    setFieldValue('dealer', dealer);
  }, [dealer]);

  const dealersOptions = values.brand.value
    ? getDealersOptionsByBrand(dealers ?? [], values.brand.value)
    : getDealersOptions(dealers ?? []);

  return (
    <Component>
      {!isFormSend ? (
        <React.Fragment>
          <Image
            loading="lazy"
            desktop={convertThumbnailToPictureImage(image)}
            laptop={convertThumbnailToPictureImage(image)}
            tabletSmall={convertThumbnailToPictureImage(imageMobile ?? image)}
            mobile={convertThumbnailToPictureImage(imageMobile ?? image)}
          />
          <Form onSubmit={handleSubmit} noValidate autoComplete="off">
            <Title dangerouslySetInnerHTML={{ __html: title }} />

            <Fields>
              <Field>
                <StockFilterInput
                  id="name"
                  name="name"
                  label="Имя"
                  value={values.name}
                  onChange={handleChange}
                  errorMessage={
                    errors.name && touched.name ? errors.name : undefined
                  }
                />
              </Field>

              <Field>
                <StockFilterInput
                  id="phone"
                  name="phone"
                  label="Телефон"
                  placeholder="+375 (__) ___-__-__"
                  value={values.phone}
                  onChange={handleChange}
                  errorMessage={
                    errors.phone && touched.phone ? errors.phone : undefined
                  }
                />
              </Field>

              {fieldsConfig?.withBrands && (
                <Field>
                  <StockFilterSelect
                    label="Марка автомобиля"
                    value={brand}
                    options={brands ?? []}
                    onChange={setBrand}
                    errorMessage={
                      errors.brand?.value && touched.brand?.value
                        ? errors.brand.value
                        : undefined
                    }
                  />
                </Field>
              )}

              {fieldsConfig?.withDealers && (
                <Field>
                  <StockFilterSelect
                    label="Автоцентр"
                    value={dealer}
                    options={dealersOptions}
                    onChange={setDealer}
                    errorMessage={
                      errors.dealer?.value && touched.dealer?.value
                        ? errors.dealer.value
                        : undefined
                    }
                  />
                </Field>
              )}

              {fieldsConfig?.withMessage && (
                <Field>
                  <StockFilterTextArea
                    id="message"
                    name="message"
                    label="Комментарий"
                    value={values.message}
                    onChange={handleChange}
                  />
                </Field>
              )}

              <CheckBoxWrapper>
                <CheckboxAgreement
                  checked={userAgreement}
                  onChange={() => setUserAgreement(!userAgreement)}
                />
              </CheckBoxWrapper>

              <ButtonWrapper>
                <Button
                  type="submit"
                  variant="contained"
                  disabled={!isSubmitting ? !userAgreement : true}
                >
                  {!isSubmitting ? buttonText : <Loader color={colors.white} />}
                </Button>
              </ButtonWrapper>
            </Fields>
          </Form>
        </React.Fragment>
      ) : (
        <SuccessForm />
      )}
    </Component>
  );
}

export default ConsultationFormSecondary;

const Component = styled.div`
  display: flex;
  justify-content: center;

  ${media.tablet(css`
    flex-direction: column;
  `)}
`;

const Image = styled(Picture)`
  flex: 1 1 755px;
  max-width: 755px;
  margin: 40px -125px 40px 0;

  ${media.tablet(css`
    flex: auto;
    max-width: none;
    padding-top: 55.557%;
    margin: 0;
  `)}

  picture,
  img {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }

  img {
    object-fit: cover;
  }
`;

const Form = styled.form`
  flex: 1 1 910px;
  max-width: 910px;
  padding: 40px 90px 40px 225px;
  background: ${(props) => props.theme.white_1};
  box-shadow: ${(props) => props.theme.blackShadow100};
  border-radius: 10px;

  ${media.tablet(css`
    z-index: 1;
    flex: auto;
    max-width: none;
    padding: 40px 15px;
    margin-top: -53px;
  `)}
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 30px;
  line-height: 35px;
  color: ${(props) => props.theme.black200};

  ${media.tablet(css`
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
  `)}
`;

const Fields = styled.div`
  margin-top: 30px;
`;

const Field = styled.div`
  textarea {
    min-height: 60px;
  }

  &:not(:first-child) {
    margin-top: 20px;
  }
`;

const CheckBoxWrapper = styled.div`
  margin-top: 30px;
`;

const ButtonWrapper = styled.div`
  max-width: 240px;
  margin-top: 20px;

  ${media.mobile(css`
    max-width: none;
  `)}
`;
